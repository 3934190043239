import React, { useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import WebProjects from '../components/Main/Portofolio/WebProjects';
import GraphicProjects from '../components/Main/Portofolio/GraphicProjects';
function Portfolio() {
   useEffect(() => {
      document.title = "Daniel's Portfolio | Projects";
    }, []);
   return (
      <>
         <WebProjects name="web" />
         <GraphicProjects name="graphic" />
         <Footer name="footer" />
      </>
   );
}

export default Portfolio;
