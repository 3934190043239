import React from 'react';
import dataGraphProjects from '../../../data/dataGraphProjects';
import InteractiveImage from '../../Utils/InteractiveImage';

function ItemHobby(props) {
   const item = dataGraphProjects[props.index];
   return (
      <div className="hobby-item">
         <div className="hobby-item__body">
            <InteractiveImage
               src={item.img}
               alt={item.name}
               className="hobby-item__img"
               bdrs="20px"
            />
            <div className="hobby-item__row">
               <div className="hobby-item__name">{item.name}</div>
               <div className="hobby-item__data">{item.date}</div>
            </div>
         </div>
      </div>
   );
}
export default ItemHobby;
