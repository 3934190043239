//svg
import reactSvg from '../assets/svg/react.svg';
import htmlSvg from '../assets/svg/html.svg';
import reduxSvg from '../assets/svg/redux.svg';
import gitSvg from '../assets/svg/git.svg';
import githubSvg from '../assets/svg/github.svg';
import jsSvg from '../assets/svg/js.svg';
// certificates
import JavaScript from '../assets/img/certificates/java-script.jpg';
import gitGithub from '../assets/img/certificates/git-github.jpg';
import reactReduxHtml from '../assets/img/certificates/react-redux-html.jpg';

// old colors
const dataCertificates = [
   {
      name: 'HTML ',
      img: reactReduxHtml,
      color: '#ffb066',
      svg: htmlSvg,
      date: 'May 11 2024',
      len: 32.5,
   },
   {
      name: 'JavaScript ',
      img: JavaScript,
      color: '#FAFF66',
      svg: jsSvg,
      date: 'Nov. 20 2023',
      len: 23.5,
   },
   {
      name: 'React',
      img: reactReduxHtml,
      color: '#80f2ff',
      svg: reactSvg,
      date: 'May 11 2024',
      len: 32.5,
   },
   {
      name: 'Redux ',
      img: reactReduxHtml,
      color: '#b080ff',
      svg: reduxSvg,
      date: 'May 11 2024',
      len: 32.5,
   },
   {
      name: 'Git ',
      img: gitGithub,
      color: '#66B3FF',
      svg: gitSvg,
      date: 'Dec. 15 2023',
      len: 4,
   },
   {
      name: 'GitHub ',
      img: gitGithub,
      color: '#ff7e61',
      svg: githubSvg,
      date: 'Dec. 15 2023',
      len: 4,
   },
];

export default dataCertificates;
