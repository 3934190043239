import React from 'react';
import Title from '../../Utils/Title';
import dataCertificates from '../../../data/dataCertificates';
import Certificate from './Certificate';
function Certificates() {
   return (
      <section className="certificates">
         <div className="certificates__body">
            <Title>My Certificates</Title>
            <div className="certificates__grid">
               {dataCertificates.map((el, idx) => {
                  return (
                     <Certificate
                        el={el}
                        key={idx}
                        src={el.img}
                        alt="item"
                        bdrs="20px"
                        bdrsImg="21px"
                     />
                  );
               })}
            </div>
         </div>
      </section>
   );
}

export default Certificates;
