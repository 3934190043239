import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useGesture } from '@use-gesture/react';
import Title from './Title';

function ContactsPopUp({ bdrsIn, bdrsOut, children }) {
   const [isOpen, setIsOpen] = useState(false);
   const [initialRect, setInitialRect] = useState({ width: 0, height: 0 });
   const [shouldClose, setShouldClose] = useState(false);
   const [isAnimating, setIsAnimating] = useState(false);

   const pRef = useRef(null);
   const animatedRef = useRef(null);

   const [
      {
         x,
         y,
         scale,
         pointerEvents,
         backgroundOpacity,
         cursor,
         width,
         height,
         interactivePointerEvents,
         divOpacity,
      },
      api,
   ] = useSpring(() => ({
      x: 0,
      y: 0,
      scale: 1,
      pointerEvents: 'all',
      backgroundOpacity: 0,
      cursor: 'auto',
      width: 0,
      height: 0,
      interactivePointerEvents: 'all', // Control pointer events for interactive div
      divOpacity: 1,
      config: { tension: 200, friction: 20 },
   }));

   const handleResize = useCallback(() => {
      if (isOpen) {
         document.body.style.paddingRight = window.matchMedia(
            '(min-width: 768px)'
         ).matches
            ? '16px'
            : '';
      }
   }, [isOpen]);

   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = 'hidden';
         handleResize();
         window.addEventListener('resize', handleResize);
      } else {
         document.body.style.overflow = '';
         document.body.style.paddingRight = '';
         window.removeEventListener('resize', handleResize);
      }

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, [isOpen, handleResize]);

   //screen data
   const screenWidth = window.innerWidth;
   const screenHeight = window.innerHeight;
   const smallerDimension = Math.min(screenWidth, screenHeight);

   // calc conent of container on mobile small
   const NUM_OF_ELEMENTS = 5;
   const HEIGHT_OF_ELEMENTS = 68;
   const HEIGHT_OF_HEADER = 78;

   const calculateHeight = useCallback(() => {
      return screenWidth < 480
         ? HEIGHT_OF_HEADER + HEIGHT_OF_ELEMENTS * NUM_OF_ELEMENTS
         : HEIGHT_OF_HEADER +
              HEIGHT_OF_ELEMENTS * Math.ceil(NUM_OF_ELEMENTS / 2);
   }, [screenWidth]);

   const openImage = useCallback(() => {
      if (isOpen) return;
      const rect = pRef.current.getBoundingClientRect();

      setInitialRect(rect);
      setIsOpen(true);
      setIsAnimating(true);
      api.start({
         scale: 1,
         pointerEvents: 'all',
         x: (window.innerWidth - smallerDimension) / 2 - rect.left + 8,
         y: (window.innerHeight - calculateHeight()) / 2 - rect.top + 8,
         width: smallerDimension - 16,
         height: calculateHeight(),
         backgroundOpacity: 1,
         cursor: 'grab',
         interactivePointerEvents: 'all',
         divOpacity: 0,
         config: { tension: 200, friction: 20 },
         onRest: () => {
            setIsAnimating(false);
            api.start({ pointerEvents: 'all', cursor: 'grab' });
         },
      });
   }, [api, isOpen, smallerDimension, calculateHeight]);

   const closeImage = useCallback(() => {
      setIsAnimating(true);
      api.start({
         x: 0,
         y: 0,
         scale: 1,
         width: initialRect.width,
         height: initialRect.height,
         backgroundOpacity: 0,
         pointerEvents: 'none',
         interactivePointerEvents: 'none',
         divOpacity: 1,
         config: { tension: 200, friction: 20 },
         onRest: () => {
            setIsOpen(false);
            setShouldClose(false);
            setIsAnimating(false);
            api.start({
               pointerEvents: 'all', // Disable interaction
               interactivePointerEvents: 'all', // Disable interactive pointer events
            });
         },
      });
   }, [api, initialRect]);

   const handleInitialClick = useCallback(() => {
      if (isOpen && shouldClose) {
         setShouldClose(false);
         api.start({
            x:
               (window.innerWidth - smallerDimension) / 2 -
               initialRect.left +
               8,
            y:
               (window.innerHeight - calculateHeight()) / 2 -
               initialRect.top +
               8,
            scale: 1,
            width: smallerDimension - 16,
            height: calculateHeight(),
            backgroundOpacity: 1,
            pointerEvents: 'all',
            cursor: 'grab',
            interactivePointerEvents: 'all',
            divOpacity: 0,
            config: { tension: 200, friction: 20 },
            onRest: () => {
               setIsOpen(true);
            },
         });
      }
   }, [
      api,
      isOpen,
      shouldClose,
      initialRect,
      calculateHeight,
      smallerDimension,
   ]);

   const handleTouchEnd = () => {
      if (isOpen && shouldClose) {
         //setShouldClose(false);
         api.start({
            x:
               (window.innerWidth - smallerDimension) / 2 -
               initialRect.left +
               8,
            y:
               (window.innerHeight - calculateHeight()) / 2 -
               initialRect.top +
               8,
            scale: 1,
            width: smallerDimension - 16,
            height: calculateHeight(),
            backgroundOpacity: 1,
            pointerEvents: 'all',
            cursor: 'grab',
            interactivePointerEvents: 'all',
            divOpacity: 0,
            config: { tension: 200, friction: 20 },
            onRest: () => {
               setIsOpen(true);
            },
         });
      }
   };

   const handleButtonClick = () => {
      setShouldClose(true);
      closeImage();
   };

   const bind = useGesture(
      {
         onDrag: ({
            down,
            movement: [mx, my],
            memo = { x: x.get(), y: y.get(), shouldClose: false },
            pinching,
         }) => {
            if (!pinching && isOpen) {
               const currentScale = scale.get();
               const rect = animatedRef.current.getBoundingClientRect();
               const imageTop = rect.top + window.scrollY;
               const imageBottom = imageTop + rect.height;
               const visibleTop = window.scrollY + window.innerHeight * 0.4;
               const visibleBottom = window.scrollY + window.innerHeight * 0.6;

               const isAboveTop = imageBottom < visibleTop + rect.height / 2;
               const isBelowBottom = imageTop > visibleBottom - rect.height / 2;

               if (currentScale === 1) {
                  if (isAboveTop || isBelowBottom) {
                     setShouldClose(true);
                  } else {
                     setShouldClose(false);
                  }
               }

               api.start({
                  x: memo.x + mx,
                  y: memo.y + my,
                  cursor: down ? 'grabbing' : 'grab',
                  pointerEvents: 'all',
                  interactivePointerEvents: 'all', // Enable interactive pointer events
                  config: { tension: 200, friction: 20 },
                  immediate: true,
               });
            }
            return memo;
         },
         onDragEnd: () => {
            document.activeElement.blur(); // Dezactivează focusul elementului activ
            if (shouldClose) {
               closeImage();
            } else if (scale.get() === 1) {
               setIsOpen(true);
               api.start({
                  x:
                     (window.innerWidth - smallerDimension) / 2 -
                     initialRect.left +
                     8,
                  y:
                     (window.innerHeight - calculateHeight()) / 2 -
                     initialRect.top +
                     8,
                  pointerEvents: 'all',
                  interactivePointerEvents: 'all',
                  config: { tension: 200, friction: 20 },
               });
            }
         },
      },
      {
         drag: { filterTaps: true },
      }
   );

   useEffect(() => {
      if (pRef.current) {
         const rect = pRef.current.getBoundingClientRect();
         api.start({
            width: rect.width,
            height: rect.height,
         });
      }
   }, [api]);

   return (
      <div
         className={`contacts-popup`}
         style={{
            borderRadius: bdrsIn,
         }}
      >
         <div
            className="contacts-popup__body"
            style={{
               opacity: !isOpen ? 1 : 0,
               height: '100%',
               borderRadius: bdrsIn,
            }}
            onClick={openImage}
            onClickCapture={handleInitialClick}
            onTouchEnd={handleTouchEnd}
            draggable="false"
            ref={pRef}
         >
            {children}
         </div>
         {isOpen && (
            <>
               <animated.div
                  style={{
                     position: 'fixed',
                     top: 0,
                     left: 0,
                     width: '100%',
                     height: '100%',
                     backgroundColor: 'rgba(0, 0, 0, 0.8)',
                     opacity: backgroundOpacity,
                     zIndex: 9998,
                     pointerEvents: `${
                        !isAnimating || !shouldClose ? 'all' : 'none'
                     }`,
                     touchAction: 'none',
                     userSelect: 'none',
                     transition: 'opacity 0.2s ease',
                  }}
                  onClick={handleButtonClick}
                  draggable="false"
               />
               <animated.div
                  style={{
                     position: 'fixed',
                     top: '8px',
                     right: '8px',
                     padding: '5px 20px',
                     borderRadius: '50px',
                     backgroundColor: 'rgba(255, 255, 255, 0.8)',
                     lineHeight: 0,
                     opacity: backgroundOpacity,
                     color: 'black',
                     zIndex: 10000,
                     transition: 'opacity 0.2s ease',
                     cursor: 'pointer',
                     pointerEvents: `${shouldClose ? 'none' : 'all'}`,
                  }}
                  onClick={handleButtonClick}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     width="35"
                     height="35"
                     viewBox="0 0 24 24"
                     style={{
                        touchAction: 'none',
                        userSelect: 'none',
                        pointerEvents: `${shouldClose ? 'none' : 'all'}`,
                     }}
                  >
                     <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2.5"
                        d="m7 7l10 10M7 17L17 7"
                     />
                  </svg>
               </animated.div>

               <animated.div
                  {...bind()}
                  ref={animatedRef}
                  draggable="false"
                  style={{
                     position: 'fixed',
                     top: initialRect.top,
                     left: initialRect.left,
                     width: width,
                     height: height,
                     transform: x.to(
                        (x) =>
                           `translate3d(${Math.round(x)}px, ${Math.round(
                              y.get()
                           )}px, 0) scale(${scale.get()})`
                     ),
                     zIndex: 9999,
                     cursor: cursor,
                     touchAction: 'none',
                     pointerEvents: pointerEvents,
                     overflow: 'hidden',
                  }}
               >
                  <animated.div
                     style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: 11000,
                        top: 0,
                        left: 0,
                        pointerEvents: interactivePointerEvents,
                        userSelect: 'none',
                        touchAction: 'none',
                        opacity: divOpacity,
                        display: isAnimating ? 'block' : 'none',
                     }}
                     draggable="false"
                  >
                     <div
                        className="contacts-popup__body"
                        style={{
                           height: '100%',
                           color: isOpen ? 'transparent' : 'white',
                           borderRadius: bdrsIn,
                        }}
                     >
                        {children}
                     </div>
                  </animated.div>

                  <div
                     className="contacts-popup__wrapper"
                     style={{
                        borderRadius: bdrsOut,
                     }}
                  >
                     <Title class="contacts-popup__title">Contacts</Title>
                     <div className="contacts-popup__networks">
                        <a
                           href="https://www.instagram.com/daniel.diacon.work/"
                           className="contacts-popup__network"
                           draggable="false"
                        >
                           <div className="contacts-popup__icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                              >
                                 <path
                                    fill="currentColor"
                                    d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                                 />
                              </svg>
                           </div>
                           <span>Instagram</span>
                        </a>
                        <a
                           href="mailto:daniel.diacon.work@gmail.com"
                           className="contacts-popup__network"
                           draggable="false"
                        >
                           <div className="contacts-popup__icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                              >
                                 <path
                                    fill="currentColor"
                                    d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64L12 9.548l6.545-4.91l1.528-1.145C21.69 2.28 24 3.434 24 5.457z"
                                 />
                              </svg>
                           </div>
                           <span>Gmail</span>
                        </a>
                        <a
                           href="https://www.linkedin.com/in/daniel-diacon-72b421264/"
                           className="contacts-popup__network"
                           draggable="false"
                        >
                           <div className="contacts-popup__icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                              >
                                 <path
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    d="M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02c3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461c-1.889 0-2.674 1.345-2.674 3.46V22h-4V8.969ZM2.57 21.83h4V8.799h-4V21.83ZM7.143 4.55a2.53 2.53 0 0 1-.753 1.802a2.573 2.573 0 0 1-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.548 2.548 0 0 1 2 4.55c0-.677.27-1.325.753-1.803A2.583 2.583 0 0 1 4.571 2c.682 0 1.336.269 1.819.747c.482.478.753 1.126.753 1.803Z"
                                    clipRule="evenodd"
                                 />
                              </svg>
                           </div>
                           <span>Linkedin</span>
                        </a>
                        <a
                           href="https://www.facebook.com/profile.php?id=100090389288908&mibextid=ZbWKwL"
                           className="contacts-popup__network"
                           draggable="false"
                        >
                           <div className="contacts-popup__icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                              >
                                 <path
                                    fill="currentColor"
                                    d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z"
                                 />
                              </svg>
                           </div>
                           <span>FaceBook</span>
                        </a>
                        <a
                           href="https://www.upwork.com/fl/~011c5907851f869e93?mp_source=share"
                           className="contacts-popup__network"
                           draggable="false"
                        >
                           <div className="contacts-popup__icon">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="1em"
                                 height="1em"
                                 viewBox="0 0 24 24"
                              >
                                 <path
                                    fill="currentColor"
                                    d="M18.561 13.158c-1.102 0-2.135-.467-3.074-1.227l.228-1.076l.008-.042c.207-1.143.849-3.06 2.839-3.06a2.705 2.705 0 0 1 2.703 2.703a2.707 2.707 0 0 1-2.704 2.702m0-8.14c-2.539 0-4.51 1.649-5.31 4.366c-1.22-1.834-2.148-4.036-2.687-5.892H7.828v7.112a2.55 2.55 0 0 1-2.547 2.548a2.55 2.55 0 0 1-2.545-2.548V3.492H0v7.112c0 2.914 2.37 5.303 5.281 5.303c2.913 0 5.283-2.389 5.283-5.303v-1.19c.529 1.107 1.182 2.229 1.974 3.221l-1.673 7.873h2.797l1.213-5.71c1.063.679 2.285 1.109 3.686 1.109c3 0 5.439-2.452 5.439-5.45c0-3-2.439-5.439-5.439-5.439"
                                 />
                              </svg>
                           </div>
                           <span>Upwork</span>
                        </a>
                     </div>
                  </div>
               </animated.div>
            </>
         )}
      </div>
   );
}

export default ContactsPopUp;
