import { NavLink as RouterLink } from 'react-router-dom';
import handleScrollToTop from './ScrollToTop';
import { ReactSVG } from 'react-svg';
import linkOpen from '../../assets/svg/arrow.svg';
function SeeMore(props) {
   return (
      <RouterLink
         to={props.src}
         onClick={handleScrollToTop}
         className={`${props.class} seemore`}
      >
         <p className="seemore__title">{props.textTitle}</p>
         <div className="seemore__link">
            <p className="seemore__text">Learn More</p>
            <ReactSVG className="seemore__icon" src={linkOpen} />
         </div>
      </RouterLink>
   );
}
export default SeeMore;
