import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import handleScrollToTop from '../../Utils/ScrollToTop';
import InteractiveImage from '../../Utils/InteractiveImage';
import Typewriter from '../../Utils/Typewriter';
import danielLogo from '../../../assets/img/daniel-diacon.jpg';
import IntroItem from './IntroItem';
import dataProjects from '../../../data/dataProjects';

function Intro() {
   const currentYear = new Date().getFullYear();
   const currentMonth = new Date().getMonth();
   let yearsExp = currentMonth >= 7 ? currentYear - 2021 : currentYear - 2022;

   // animation text
   const [isDeveloper, setIsDeveloper] = useState(true);
   const [currentWord, setCurrentWord] = useState('developer');

   useEffect(() => {
      const switchWord = () => {
         setIsDeveloper((prev) => !prev);
      };

      const intervalId = setInterval(switchWord, 6000); // Switch every 4 seconds

      return () => clearInterval(intervalId);
   }, []);

   useEffect(() => {
      setCurrentWord(isDeveloper ? 'developer' : 'designer');
   }, [isDeveloper]);

   return (
      <section className="intro">
         <div className="intro__container">
            <div className="intro__body">
               <div className="intro__info">
                  <h1 className="intro__title">
                     HI, I'm Daniel, a passionate front-end{' '}
                     <Typewriter word={currentWord} />
                  </h1>
                  <div className="intro__achievements">
                     <IntroItem>
                        {yearsExp} <br />
                        Years of Experience
                     </IntroItem>
                     <Link
                        to="/portfolio"
                        className="intro__item"
                        onClick={handleScrollToTop}
                     >
                        {dataProjects.length}
                        <br />
                        Projects
                        <svg
                           width="10"
                           height="10"
                           viewBox="0 0 10 10"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M2.82843 10C1.04662 10 0.154284 7.84572 1.41421 6.58579L6.58578 1.41422C7.84571 0.154286 10 1.04662 10 2.82843V8C10 9.10457 9.10457 10 8 10H2.82843Z"
                              fill="currentColor"
                           />
                        </svg>
                     </Link>

                     <IntroItem>
                        2 <br />
                        Clients
                     </IntroItem>
                  </div>
               </div>
               <InteractiveImage
                  className="intro__img"
                  src={danielLogo}
                  alt="Daniel Logo"
                  bdrs="32px"
                  linkIcon="true"
               />
            </div>
         </div>
      </section>
   );
}

export default Intro;
