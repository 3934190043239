import React, { useRef } from 'react';
import ItemLastWorks from './ItemLastWorks';
import Title from '../../Utils/Title';
import SeeMore from '../../Utils/SeeMore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

function LastWorks() {
   const swiperRef = useRef(null);

   const handleInteractionStart = () => {
      const swiper = swiperRef.current.swiper;
      swiper.slides.forEach((slide) => slide.classList.add('scaled-down'));
   };

   const handleInteractionEnd = () => {
      const swiper = swiperRef.current.swiper;
      swiper.slides.forEach((slide) => slide.classList.remove('scaled-down'));
   };

   return (
      <section className="lastworks">
         <div className="lastworks__body">
            <SeeMore
               class="lastworks__left"
               src="/portfolio"
               textTitle="Recent Web Works"
            />
            <div className="lastworks__right">
               <div className="lastworks__slider">
                  <Swiper
                     ref={swiperRef}
                     spaceBetween={8}
                     slidesPerView={2.2}
                     autoHeight={true}
                     pagination={{
                        dynamicBullets: true,
                     }}
                     modules={[Pagination]}
                     breakpoints={{
                        320: {
                           slidesPerView: 1.2,
                        },
                        640: {
                           slidesPerView: 1.2,
                        },
                        1150: {
                           slidesPerView: 1.2,
                        },
                        1190: {
                           slidesPerView: 2.2,
                        },
                     }}
                     onTouchStart={handleInteractionStart}
                     onTouchEnd={handleInteractionEnd}
                     onMouseDown={handleInteractionStart}
                     onMouseUp={handleInteractionEnd}
                  >
                     <SwiperSlide key="slide0">
                        <ItemLastWorks events="true" index={0} />
                     </SwiperSlide>
                     <SwiperSlide key="slide1">
                        <ItemLastWorks events="true" index={1} />
                     </SwiperSlide>
                     <SwiperSlide key="slide2">
                        <ItemLastWorks events="true" index={2} />
                     </SwiperSlide>
                     <SwiperSlide key="slide3">
                        <ItemLastWorks events="true" index={3} />
                     </SwiperSlide>
                  </Swiper>
               </div>
            </div>
         </div>
      </section>
   );
}

export default LastWorks;
