import DarkModeToggle from './DarkModeToggle';
import { toggleSettings } from './toggleSettings';

function SettingsComponent() {
   return (
      <>
         <DarkModeToggle />

         <div
            className="header__settings-btn"
            id="btn-settings"
            onClick={toggleSettings}
         >
            <svg
               className="header__settings-icon"
               xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               viewBox="0 0 24 24"
            >
               <g fill="none" stroke="currentColor" strokeWidth="2">
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     d="M14 21h-4l-.551-2.48a6.991 6.991 0 0 1-1.819-1.05l-2.424.763l-2-3.464l1.872-1.718a7.055 7.055 0 0 1 0-2.1L3.206 9.232l2-3.464l2.424.763A6.992 6.992 0 0 1 9.45 5.48L10 3h4l.551 2.48a6.992 6.992 0 0 1 1.819 1.05l2.424-.763l2 3.464l-1.872 1.718a7.05 7.05 0 0 1 0 2.1l1.872 1.718l-2 3.464l-2.424-.763a6.99 6.99 0 0 1-1.819 1.052L14 21z"
                  />
                  <circle cx="12" cy="12" r="3" />
               </g>
            </svg>
         </div>
      </>
   );
}

export default SettingsComponent;
