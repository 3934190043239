import React, { useRef, useEffect, useState } from 'react';
import dataProjects from '../../../data/dataProjects';
import InteractiveImage from '../../Utils/InteractiveImage';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

function ItemLastWorks(props) {
   const item = dataProjects[props.index];
   const containerRef = useRef(null);
   const [shouldMarquee, setShouldMarquee] = useState(false);

   useEffect(() => {
      const container = containerRef.current;
      const textElement = container;

      // Check if the text overflows the container
      if (textElement.scrollWidth > container.clientWidth) {
         setShouldMarquee(true);
      } else {
         setShouldMarquee(false);
      }
   }, [item.name]);

   return (
      <div className="item-last-works">
         <div className="item-last-works__body">
            <div className="item-last-works__row">
               <InteractiveImage
                  className={`item-last-works__img ${item.typeLogo}`}
                  src={item.img}
                  alt="item"
                  bdrs="20px"
                  events={props.events}
               />
               <div
                  className="item-last-works__name-site-wrapper"
                  ref={containerRef}
               >
                  {shouldMarquee ? (
                     <Marquee
                        speed={50}
                        autoFill="true"
                        gradient={false}
                        pauseOnHover={true}
                     >
                        <div className="item-last-works__name-site space">
                           {item.name}
                        </div>
                     </Marquee>
                  ) : (
                     <div className="item-last-works__name-site">
                        {item.name}
                     </div>
                  )}
               </div>
            </div>
            <div className="item-last-works__row item-last-works__desctiption text">
               {item.decript}
            </div>
            <div className="item-last-works__row">
               <div className="item-last-works__data text">{item.date}</div>
            </div>
            <svg
               width="14"
               height="14"
               viewBox="0 0 10 10"
               xmlns="http://www.w3.org/2000/svg"
               className="item-last-works__svg"
            >
               <path d="M2.82843 10C1.04662 10 0.154284 7.84572 1.41421 6.58579L6.58578 1.41422C7.84571 0.154286 10 1.04662 10 2.82843V8C10 9.10457 9.10457 10 8 10H2.82843Z" />
            </svg>
         </div>
         <Link to={item.src} className="item-last-works__link" />
         <Link to={item.src} className="item-last-works__link-2" />
      </div>
   );
}

export default ItemLastWorks;
