import { ReactSVG } from 'react-svg';
import Title from '../../Utils/Title';
import dataGraphProjects from '../../../data/dataGraphProjects';
import SeeMore from '../../Utils/SeeMore';
import InteractiveImage from '../../Utils/InteractiveImage';
import ContactsPopUp from '../../Utils/ContactsPopUp';
import linkOpen from '../../../assets/svg/arrow.svg';

function Hobbyes() {
   return (
      <section className="hobbyes">
         <div className="hobbyes__body">
            <div className="hobbyes__info">
               <div className="hobbyes__info-content">
                  <Title class="hobbyes__title">
                     My Hobbies: Graphic Design and Web Design
                  </Title>
                  <p className="hobbyes__text">
                     I am passionate about graphic and web design, using Adobe
                     Photoshop for creating and editing images and Figma for
                     designing websites and vector graphics. These tools allow
                     me to explore my creativity, develop a wide range of
                     designs, and continuously improve my skills.
                  </p>
               </div>

               <div className="hobbyes__contact">
                  <ContactsPopUp bdrsIn="32px" bdrsOut="32px">
                     <div className="skills__contact" draggable="false">
                        <div className="skills__contact-text">Contact Me</div>
                        <ReactSVG className="skills__icon" src={linkOpen} />
                     </div>
                  </ContactsPopUp>
               </div>
            </div>
            <InteractiveImage
               src={dataGraphProjects[dataGraphProjects.length - 2].img}
               alt={dataGraphProjects[dataGraphProjects.length - 2].name}
               className="hobby-item__img"
               bdrs="32px"
            />
            <InteractiveImage
               src={dataGraphProjects[dataGraphProjects.length - 4].img}
               alt={dataGraphProjects[dataGraphProjects.length - 4].name}
               className="hobby-item__img"
               bdrs="32px"
            />
            <InteractiveImage
               src={dataGraphProjects[dataGraphProjects.length - 6].img}
               alt={dataGraphProjects[dataGraphProjects.length - 6].name}
               className="hobby-item__img"
               bdrs="32px"
            />
            <SeeMore
               src="/portfolio"
               class="hobbyes__seemore"
               textTitle="Explore My Graphic Design Projects"
            />
         </div>
      </section>
   );
}
export default Hobbyes;
