import { ReactSVG } from 'react-svg';
import Title from '../../Utils/Title';
import Skill from './Skill';
import dataSkills from '../../../data/dataSkills';
import ContactsPopUp from '../../Utils/ContactsPopUp';
import linkOpen from '../../../assets/svg/arrow.svg';
function Skills() {
   return (
      <section className="skills">
         <div className="skills__body">
            <div className="skills__right">
               <Title className="skills__title">My Skills</Title>
               <div className="skills__grid">
                  {dataSkills.map((skill, id) => {
                     return <Skill idClass={id} key={skill.name} {...skill} />;
                  })}
               </div>
            </div>
            <div className="skills__left">
               <div className="skills__left-top">
                  <Title className="skills__title">My Skills</Title>
                  <p className="skills__text">
                     I am proficient in HTML and CSS, with a preference for SCSS
                     for more efficient styling. I also have experience with
                     JavaScript and have been working with React for the past
                     year, building and maintaining dynamic, responsive web
                     applications.
                  </p>
               </div>
               <div className="skills__left-bottom">
                  <ContactsPopUp bdrsIn="32px" bdrsOut="32px">
                     <div className="skills__contact" draggable="false">
                        <div className="skills__contact-text">Contact Me</div>
                        <ReactSVG className="skills__icon" src={linkOpen} />
                     </div>
                  </ContactsPopUp>
               </div>
            </div>
         </div>
      </section>
   );
}
export default Skills;
