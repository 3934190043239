import React, { useState, useEffect } from 'react';

const getRandomDelay = () => Math.floor(Math.random() * 150) + 50;

const Typewriter = ({ word }) => {
   const [displayedText, setDisplayedText] = useState('');
   const [isDeleting, setIsDeleting] = useState(false);
   const [isTypingComplete, setIsTypingComplete] = useState(false);

   useEffect(() => {
      let timeout;

      if (isDeleting) {
         if (displayedText.length > 0) {
            timeout = setTimeout(() => {
               setDisplayedText((prev) => prev.slice(0, -1));
            }, getRandomDelay());
         } else {
            setIsDeleting(false);
            setIsTypingComplete(false); // Reset typing complete status
         }
      } else {
         if (displayedText.length < word.length) {
            timeout = setTimeout(() => {
               setDisplayedText((prev) => word.slice(0, prev.length + 1));
            }, getRandomDelay());
         } else if (!isTypingComplete) {
            timeout = setTimeout(() => {
               setDisplayedText((prev) => prev + '.');
               setIsTypingComplete(true); // Mark typing as complete
            }, getRandomDelay());
         }
      }

      return () => clearTimeout(timeout);
   }, [displayedText, word, isDeleting, isTypingComplete]);

   useEffect(() => {
      setIsDeleting(true);
   }, [word]);

   return (
      <span className={`typewriter ${isTypingComplete ? 'ended' : ''}`}>
         <span className="typewriter__visible">
            {displayedText}
            <span className="typewriter__bar">|</span>
         </span>
         <span className="typewriter__contain">{word}</span>
      </span>
   );
};

export default Typewriter;
