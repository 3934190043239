import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import './App.scss';
import HomePage from './pages/HomePage';
import Portfolio from './pages/Portfolio';
import Header from './components/Header/Header';
import NotFound from './pages/NotFound';

const App = () => {
   document.addEventListener(
      'wheel',
      function (e) {
         if (e.ctrlKey) {
            e.preventDefault();
         }
      },
      { passive: false }
   );
   return (
      <BrowserRouter basename="/">
         <div className="App">
            <Header />
            <main className="main">
               <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="portfolio" element={<Portfolio />} />
                  <Route path="*" element={<NotFound />} />
               </Routes>
            </main>
         </div>
      </BrowserRouter>
   );
};

export default App;
