import ae86 from '../assets/img/illustrations/ae86-min.webp';
import asteroid from '../assets/img/illustrations/asteroid-min.webp';
import burn from '../assets/img/illustrations/burn-min.webp';
import codeCoffe from '../assets/img/illustrations/code-coffee-min.webp';
import flourishes from '../assets/img/illustrations/flourishes-min.webp';
import humanBuildings from '../assets/img/illustrations/human-buildings-min.webp';
import regret from '../assets/img/illustrations/regret-min.webp';
import solarSystem from '../assets/img/illustrations/solar-system-min.webp';
import theTrash from '../assets/img/illustrations/the-trash-min.webp';
import vanGogh from '../assets/img/illustrations/van-gogh-min.webp';
import yourDream from '../assets/img/illustrations/your-dream-min.webp';
import what from '../assets/img/illustrations/what-min.webp';

const dataProjects = [
   {
      name: 'Asteroid',
      date: 'September 2023',
      img: asteroid,
   },
   {
      name: 'Human Buildings',
      date: 'April 2023',
      img: humanBuildings,
   },
   {
      name: 'What? What?',
      date: 'April 2023',
      img: what,
   },
   {
      name: 'Regret',
      date: 'April 2023',
      img: regret,
   },
   {
      name: 'Go to Your Dream',
      date: 'March 2023',
      img: yourDream,
   },
   {
      name: 'The Forests are on Fire',
      date: 'March 2023',
      img: burn,
   },
   {
      name: 'Among the Trash',
      date: 'March 2023',
      img: theTrash,
   },
   {
      name: 'Code Coffe',
      date: 'March 2023',
      img: codeCoffe,
   },
   {
      name: 'Flourishes',
      date: 'March 2023',
      img: flourishes,
   },
   {
      name: 'Solar System',
      date: 'February 2023',
      img: solarSystem,
   },
   {
      name: 'Toyota AE86',
      date: 'February 2023',
      img: ae86,
   },
   {
      name: 'Van Gogh',
      date: 'February 2023',
      img: vanGogh,
   },
];

export default dataProjects;
