import Title from '../../Utils/Title';
import dataProjects from '../../../data/dataGraphProjects';
import ItemHobby from '../Hobbyes/ItemHobby';
function GraphicProjects() {
   return (
      <section className="graphic-projects">
         <div className="graphic-projects__body">
            <Title className="graphic-projects__title">My Graphic Design Projects</Title>
            <div className="graphic-projects__grid">
               {dataProjects.map((p, index) => {
                  return <ItemHobby index={index} key={index} />;
               })}
            </div>
         </div>
      </section>
   );
}
export default GraphicProjects;
