import aiart from '../assets/img/sites/aiart.png';
import bf from '../assets/img/sites/bf.png';
import oldD from '../assets/img/sites/oldD.png';
import ut from '../assets/img/sites/ut.png';
import player from '../assets/img/sites/player.png';
import cipi2 from '../assets/img/sites/cipi2.png';
import styzzo from '../assets/img/sites/styzzo.png';
import daniel from '../assets/img/logos/letter-d.png';
import byte2byte from '../assets/img/sites/logo-png.png';
import styzzoBeats from '../assets/img/sites/styzzoBeats.png';

const dataProjects = [
   {
      name: '513 | React',
      date: 'July 2024',
      img: styzzoBeats,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/styzzo.beats/',
      decript: 'Here you will find beats made by the Styzzo Beats team.',
   },
   {
      name: 'Styzzo Shop',
      date: 'May 2024',
      img: styzzo,
      typeLogo: 'white',
      src: 'https://styzzo.shop/',
      decript:
         'Styzzo offers high-quality custom clothing, including T-shirts and hoodies, with unique design and attention to detail. ',
   },
   {
      name: "Chippies' Portfolio",
      date: 'November 2023',
      img: cipi2,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/chippies-portfolio/',
      decript:
         'This is the site where you will find all the projects of the graphic designer Cipi and his contacts.',
   },
   {
      name: 'ByteToByte | Unfinished',
      date: 'October 2023',
      img: byte2byte,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/byte2byte/',
      decript:
         'Each game is a cryptic journey, echoing coding languages without revealing their technical nature.',
      unfinished: true,
   },
   {
      name: 'Daniels Portfolio v1',
      date: 'September 2023',
      img: daniel,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/daniel-diacon/',
      decript:
         'This website is designed to share my journey as a web developer, showcase my projects, provide insights into my path to becoming a web developer, and offer information about the technologies and tools I employed for these projects.',
   },
   {
      name: 'D Player | JS',
      date: 'August 2023',
      img: player,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/d.player/',
      decript:
         '"D Player" is a modern JavaScript audio player with a futuristic blur and neon design, friendly interface and advanced functionality for online music playback.',
   },
   {
      name: 'UseTool',
      date: 'February 2023',
      img: ut,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/UseTool/',
      decript:
         'This site was created to help frontend developers and web designers of different levels. On this page you will find many sites, channels and neural networks that will help you improvise your skills or ease some of the stages of creating your projects.',
   },
   {
      name: 'Birch Forest',
      date: 'January 2023',
      img: bf,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/BirchForest/',
      decript:
         'The site uses the parallax effect and features an image of a birch forest that appears to move in illusory 3D as the page is scrolled.',
   },
   {
      name: 'AIArt',
      date: 'January 2023',
      img: aiart,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/AIArt/',
      decript:
         'Web page with images created by DALL-E neural network. The ideas of the images belong to the creator of the page (Deacon Daniel).',
   },

   {
      name: 'WebD',
      date: 'August 2022',
      img: oldD,
      typeLogo: 'white',
      src: 'https://danieldiacon.github.io/WebD/',
      decript:
         'On this site you will find many projects of front end and graphic developer Diacon Daniel. You will also be able to read his story about how he became a front end developer.',
   },
];

export default dataProjects;
