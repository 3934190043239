import React, { useEffect } from 'react';
import Intro from '../components/Main/Intro/Intro';
import LastWorks from '../components/Main/LastWorks/LastWorks';
import Skills from '../components/Main/Skills/Skills';
import Footer from '../components/Footer/Footer';
import Hobbyes from '../components/Main/Hobbyes/Hobbyes';
import Certificates from '../components/Main/Certificates/Certificates';

function HomePage() {
   useEffect(() => {
      document.title = "Daniel's Portfolio | Home";
   }, []);
   return (
      <>
         <Intro name="intro" />
         <LastWorks name="lastworks" />
         <Skills name="skills" />
         <Certificates name="certificates" />
         <Hobbyes name="hobbyes" />
         <Footer name="footer" />
      </>
   );
}

export default HomePage;
