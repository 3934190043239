import React from 'react';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/svg/logoDaniel.svg';

function Footer() {
   return (
      <footer className="footer" id="contacts">
         <div className="footer__container">
            <div className="footer__body">
               <div className="footer__row">
                  <div className="footer__logo-app">
                     <ReactSVG className="footer__logo" src={logo} />
                  </div>
                  <div className="footer__networks">
                     <a
                        href="https://www.instagram.com/daniel.diacon.work/"
                        className="footer__network"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                        >
                           <path
                              fill="currentColor"
                              d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                           />
                        </svg>
                     </a>
                     <a
                        href="mailto:daniel.diacon.work@gmail.com"
                        className="footer__network"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                        >
                           <path
                              fill="currentColor"
                              d="M24 5.457v13.909c0 .904-.732 1.636-1.636 1.636h-3.819V11.73L12 16.64l-6.545-4.91v9.273H1.636A1.636 1.636 0 0 1 0 19.366V5.457c0-2.023 2.309-3.178 3.927-1.964L5.455 4.64L12 9.548l6.545-4.91l1.528-1.145C21.69 2.28 24 3.434 24 5.457z"
                           />
                        </svg>
                     </a>
                     <a
                        href="https://www.linkedin.com/in/daniel-diacon-72b421264/"
                        className="footer__network"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                        >
                           <path
                              fill="currentColor"
                              fillRule="evenodd"
                              d="M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02c3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461c-1.889 0-2.674 1.345-2.674 3.46V22h-4V8.969ZM2.57 21.83h4V8.799h-4V21.83ZM7.143 4.55a2.53 2.53 0 0 1-.753 1.802a2.573 2.573 0 0 1-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.548 2.548 0 0 1 2 4.55c0-.677.27-1.325.753-1.803A2.583 2.583 0 0 1 4.571 2c.682 0 1.336.269 1.819.747c.482.478.753 1.126.753 1.803Z"
                              clipRule="evenodd"
                           />
                        </svg>
                     </a>
                     <a
                        href="https://www.facebook.com/profile.php?id=100090389288908&mibextid=ZbWKwL"
                        className="footer__network"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="24"
                           height="24"
                           viewBox="0 0 24 24"
                        >
                           <path
                              fill="currentColor"
                              d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5Z"
                           />
                        </svg>
                     </a>
                  </div>
               </div>
               <div className="footer__row">
                  <div className="footer__copy-right">
                     © 2023 Daniel, All rights reserved.
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
}

export default Footer;
