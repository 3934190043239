const dataSkills = [
   {
      name: 'HTML',
      progress: 83,
      colorLighter: '#FFEE54',
      color: '#ffa366',
   },

   {
      name: 'React',
      progress: 80,
      colorLighter: '#74A0FF',
      color: '#61EEFF',
   },
   {
      name: 'JS',
      progress: 60,
      colorLighter: '#A1FF66',
      color: '#FFFF61',
   },
   {
      name: 'CSS',
      progress: 92,
      colorLighter: '#7366FF',
      color: '#66B3FF',
   },
   {
      name: 'Redux',
      progress: 70,
      colorLighter: '#FA64FF',
      color: '#9c7dcf',
   },
   {
      name: 'Git',
      progress: 50,
      colorLighter: '#FFD761',
      color: '#FF7D61',
   },
   {
      name: 'SCSS',
      progress: 75,
      colorLighter: '#FF617E',
      color: '#FF61DA',
   },
   {
      name: 'GitHub',
      progress: 50,
      colorLighter: '#7F66FF',
      color: '#66A6FF',
   },
   {
      name: 'NPM',
      progress: 50,
      colorLighter: '#FFBF66',
      color: '#FF6666',
   },
   //{
   //   name: 'TS',
   //   progress: 10,
   //   colorLighter: '#AD61FF',
   //   color: '#6171FF',
   //},
];
export default dataSkills;
