import React from 'react';

function Skill({ name, color, idClass }) {
   return (
      <div className={`skills__item item-skills item-skills-${idClass}`}>
         <div className="item-skills__name">
            {name}
            {/*{idClass}*/}
         </div>
         <div className="item-skills__bg" style={{ background: color }}></div>
      </div>
   );
}

export default Skill;
