import ItemLastWorks from '../LastWorks/ItemLastWorks';
import Title from '../../Utils/Title';
import dataProjects from '../../../data/dataProjects';

function WebProjects() {
   return (
      <section className="web-projects" id="webProjects">
         <div className="web-projects__body">
            <Title className="web-projects__title">
               My Web Development Projects.
            </Title>
            <div className="web-projects__grid">
               {dataProjects.map((p, index) => {
                  return <ItemLastWorks index={index} key={index} />;
               })}
            </div>
         </div>
      </section>
   );
}
export default WebProjects;
