import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Renderizează componenta principală a aplicației în elementul cu id-ul 'root'
ReactDOM.render(
   <React.StrictMode>
      <App />
   </React.StrictMode>,
   document.getElementById('root')
);
